import * as React from "react"
import PropTypes from "prop-types"
import { useTranslator } from "components/Translator"
import { Tag } from "components/Tag"
import { Text } from "components/Text"
import { Check } from "components/Icon/Check"
import { Heart } from "components/Icon/Heart"
import { HeartRegular } from "components/Icon/HeartRegular"
import { RequireLogin } from "components/RequireLogin"
import { useAdvertInteractions } from "domains/advert/AdvertInteractionsProvider"
import { ShareAlt } from "components/Icon/ShareAlt"
import { ShareModal } from "components/ShareModal"
import cx from "classnames"
import { useRouter } from "components/Router"

export const AdvertInteractions = (props) => {
  const { variant, advert } = props
  const [showShareModal, setShowShareModal] = React.useState(false)
  const advertInteractions = useAdvertInteractions()
  const interactionsData = advertInteractions.get(advert.id)
  const router = useRouter()
  const translator = useTranslator()

  const HeartIcon = interactionsData?.favorite ? Heart : HeartRegular

  const handleLikeClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    advertInteractions.toggleFavorite(advert.id)
  }

  const url = `${window.location.origin}${router.generate(
    "app_account_advert_show",
    {
      advertId: advert.id,
      userId: advert.user.id,
    },
  )}`

  const subject = encodeURIComponent(
    translator.trans("ShareModal.byMail.subject", null, "components"),
  )
  const body = translator.trans(
    "ShareModal.byMail.body",
    { url: encodeURIComponent(url) },
    "components",
  )

  const handleShareClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setShowShareModal(true)
  }

  return (
    <div className="flex flex-row justify-between items-center">
      {interactionsData?.lastContact ? (
        <Text
          className={cx({ "text-white": variant === "light" })}
          variant="caption"
        >
          {translator.trans("AdvertCard.lastContact", null, "components")}{" "}
          {new Date(interactionsData.lastContact).toLocaleDateString(
            translator.locale,
          )}
        </Text>
      ) : interactionsData?.lastVisit ? (
        <Tag
          color={variant === "light" ? "default-contrast" : "default"}
          label={translator.trans("AdvertCard.lastVisit", null, "components")}
          icon={<Check className="w-4" />}
        />
      ) : null}
      <RequireLogin>
        <button
          type="button"
          className="flex ml-auto p-2 bg-white items-center rounded-full"
          onClick={handleLikeClick}
        >
          <HeartIcon className={cx("w-6 text-primary-dark")} />
        </button>
      </RequireLogin>
      <button
        type="button"
        className="flex ml-auto p-2 bg-white rounded-full"
        onClick={handleShareClick}
      >
        <ShareAlt className={cx("w-6 text-primary-dark")} />
      </button>
      <ShareModal
        isOpen={showShareModal}
        onRequestClose={() => setShowShareModal(false)}
        title={translator.trans("ShareModal.title", null, "components")}
        url={url}
        email={{ subject: subject, body: body }}
      />
    </div>
  )
}

AdvertInteractions.propTypes = {
  variant: PropTypes.oneOf(["default", "light"]).isRequired,
}
