import { UserInfos } from "components/UserInfos"
import { Card, CardContent, CardMedia } from "components/Card"
import * as AdvertType from "enums/AdvertType"
import { Image } from "components/Image"
import { useAdvertInteractions } from "domains/advert/AdvertInteractionsProvider"
import cx from "classnames"
import { AdvertInteractions } from "domains/advert/AdvertInteractions"
import { AdvertRoute } from "domains/advert/AdvertRoute"
import { AdvertDate } from "domains/advert/AdvertDate"
import { AdvertTitle } from "domains/advert/AdvertTitle"
import { AdvertDescription } from "domains/advert/AdvertDescription"
import { useRouter } from "components/Router"
import NoImagePlaceholder from "components/NoImagePlaceholder/NoImagePlaceholder.png"
import { Text } from "components/Text"
import { useTranslator } from "components/Translator"
import { ValueWithUnit } from "components/ValueWithUnit"
import { Price } from "components/Price"
import { Anchor } from "components/Icon"
import { Tag } from "components/Tag"
import * as User from "domains/user/model"

export const AdvertResult = ({ advert }) => {
  const router = useRouter()
  const Component = advertTypeToComponent[advert.type.enum_value]
  const params = {
    userId: advert.user.id,
    advertId: advert.id,
  }

  if (!Component) {
    console.warn(`Unknown advert type: ${advert.type.enum_value}`)
    return null
  }

  return (
    <Component
      advert={advert}
      href={router.generate("app_account_advert_show", params)}
    />
  )
}

const AdvertCrewResult = ({ advert, href }) => {
  return (
    <Card>
      <div className={"relative"}>
        <AdvertResultHeader advert={advert} />
        <CardContent>
          <div className="space-y-2">
            <a
              href={href}
              className={"before:content-[''] before:absolute before:inset-0"}
            >
              <AdvertTitle advert={advert} />
            </a>
            <div
              className={cx("px-5 py-4", {
                "bg-primary-lighter": !User.isPro(advert.user),
                "bg-primary-default": User.isPro(advert.user),
              })}
            >
              <UserInfos user={advert.user} variant="compact" />
            </div>
            <AdvertRoute advert={advert} variant="compact" />
            <AdvertDescription advert={advert} />
          </div>
        </CardContent>
      </div>
    </Card>
  )
}

const AdvertBoatResult = ({ advert, href }) => {
  return (
    <Card>
      <div className={"relative"}>
        <AdvertResultHeader advert={advert} />
        <CardContent>
          <div className="space-y-2">
            <a
              href={href}
              className={"before:content-[''] before:absolute before:inset-0"}
            >
              <AdvertTitle advert={advert} />
            </a>
            <AdvertRoute advert={advert} variant="compact" />
            <AdvertDescription advert={advert} />
          </div>
        </CardContent>
        <CardContent
          className={
            User.isPro(advert.user)
              ? "bg-primary-default"
              : "bg-primary-lighter"
          }
        >
          <UserInfos user={advert.user} variant="minimal" />
        </CardContent>
      </div>
    </Card>
  )
}

const AdvertSaleResult = ({ advert, href }) => {
  const translator = useTranslator()

  return (
    <Card>
      <div className={"relative"}>
        <AdvertResultHeader advert={advert} />
        <CardContent>
          <div className="space-y-2">
            <a
              href={href}
              className={"before:content-[''] before:absolute before:inset-0"}
            >
              <AdvertTitle advert={advert} />
            </a>
            {advert.boat ? (
              <>
                <Text className="text-light">
                  {advert.boat.brandNormalized} {advert.boat.modelNormalized}
                </Text>
                {advert.boat.length?.value ? (
                  <Text className="text-light">
                    {translator.trans(
                      "AdvertCard.boat.length",
                      null,
                      "components",
                    )}
                    {" : "}
                    <ValueWithUnit value={advert.boat.length} />
                  </Text>
                ) : null}
              </>
            ) : null}
            <Text variant="headline5">
              {advert.salePrice ? (
                <Price
                  amount={advert.salePrice.amount}
                  currency={advert.salePrice.currency.enum_value}
                />
              ) : null}
            </Text>
          </div>
        </CardContent>
        <CardContent>
          <AdvertDescription advert={advert} />
        </CardContent>
        <CardContent
          className={
            User.isPro(advert.user)
              ? "bg-primary-default"
              : "bg-primary-lighter"
          }
        >
          <UserInfos user={advert.user} variant="minimal" />
        </CardContent>
      </div>
    </Card>
  )
}

const AdvertRentalResult = ({ advert, href }) => {
  const translator = useTranslator()

  return (
    <Card>
      <div className={"relative"}>
        <AdvertResultHeader advert={advert} />
        <CardContent>
          <div className="space-y-2">
            <a
              href={href}
              className={"before:content-[''] before:absolute before:inset-0"}
            >
              <AdvertTitle advert={advert} />
            </a>
            <Text className="text-light">
              {advert.boat.brandNormalized} {advert.boat.modelNormalized}
            </Text>
            {advert.boat.length?.value ? (
              <Text className="text-light">
                {translator.trans("AdvertCard.boat.length", null, "components")}
                {" : "}
                <ValueWithUnit value={advert.boat.length} />
              </Text>
            ) : null}
            <AdvertDescription advert={advert} />
          </div>
        </CardContent>
        <CardContent
          className={
            User.isPro(advert.user)
              ? "bg-primary-default"
              : "bg-primary-lighter"
          }
        >
          <UserInfos user={advert.user} variant="minimal" />
        </CardContent>
      </div>
    </Card>
  )
}

const AdvertFleetResult = ({ advert, href }) => {
  return (
    <Card>
      <div className={"relative"}>
        <AdvertResultHeader advert={advert} />
        <CardContent>
          <div className="space-y-2">
            <a
              href={href}
              className={"before:content-[''] before:absolute before:inset-0"}
            >
              <AdvertTitle advert={advert} />
            </a>
            <AdvertRoute advert={advert} variant="compact" />
            <AdvertDescription advert={advert} />
          </div>
        </CardContent>
        <CardContent
          className={
            User.isPro(advert.user)
              ? "bg-primary-default"
              : "bg-primary-lighter"
          }
        >
          <UserInfos user={advert.user} variant="minimal" />
        </CardContent>
      </div>
    </Card>
  )
}

const AdvertResultHeader = ({ advert }) => {
  const advertInteractions = useAdvertInteractions()
  const interactionsData = advertInteractions.get(advert.id)
  const visited = interactionsData?.lastContact ?? interactionsData?.lastVisit

  const imageFile = advert.imageFile || advert.boat?.imageFile
  const type = advert.type.enum_value

  const translator = useTranslator()

  return (
    <div className="space-y-2">
      {type !== AdvertType.CREW ? (
        <CardMedia
          media={
            imageFile ? (
              <Image
                imageFile={imageFile}
                size="w640"
                className={cx({ "filter grayscale": visited })}
              />
            ) : (
              <img src={NoImagePlaceholder} alt="" loading={"lazy"} />
            )
          }
          className="h-60"
        />
      ) : null}
      <div className="px-4 space-y-2">
        <div className="flex flex-row items-center justify-between">
          <Text variant="caption" className="text-light">
            #{advert.id}
          </Text>
          <AdvertDate advert={advert} />

          {/* z-index is for these actions to be reachable in a stretched link context */}
          <div className={"relative z-10"}>
            <AdvertInteractions advert={advert} variant="default" />
          </div>
        </div>
        {[AdvertType.BOAT, AdvertType.FLEET].includes(advert.type.enum_value) &&
        advert.completed ? (
          <div>
            <Tag
              color="error"
              label={translator.trans("SearchResult.full", null, "components")}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

const advertTypeToComponent = {
  [AdvertType.CREW]: AdvertCrewResult,
  [AdvertType.BOAT]: AdvertBoatResult,
  [AdvertType.SALE]: AdvertSaleResult,
  [AdvertType.RENTAL]: AdvertRentalResult,
  [AdvertType.FLEET]: AdvertFleetResult,
}

export const BoatResult = ({ boat }) => {
  const router = useRouter()

  const params = {
    userId: boat.user.id,
    boatId: boat.id,
  }

  return (
    <Card>
      <div className={"relative"}>
        <CardMedia
          media={
            boat.imageFile ? (
              <Image
                imageFile={boat.imageFile}
                size="w640"
                className="object-cover"
              />
            ) : (
              <img src={NoImagePlaceholder} alt="" loading={"lazy"} />
            )
          }
          className="h-48"
        />
        <CardContent>
          <div className="space-y-2">
            <a
              href={router.generate("app_account_boat_show", params)}
              className={"before:content-[''] before:absolute before:inset-0"}
            >
              <Text variant="headline3">{boat.name}</Text>
            </a>
            <Text>{`${boat.type.enum_label} ${boat.brandNormalized} ${boat.modelNormalized}`}</Text>
            <Text>{boat.family.enum_label}</Text>
            {boat.address?.search ? (
              <div className="flex space-x-1 items-baseline">
                <span className="shrink-0">
                  <Anchor className="text-primary-default w-4" />
                </span>
                <Text>{boat.address.search}</Text>
              </div>
            ) : null}
          </div>
        </CardContent>
        <CardContent
          className={
            User.isPro(boat.user) ? "bg-primary-default" : "bg-primary-lighter"
          }
        >
          <UserInfos user={boat.user} variant="minimal" />
        </CardContent>
      </div>
    </Card>
  )
}

export const UserResult = ({ user }) => {
  const router = useRouter()
  const params = {
    userId: user.id,
  }

  return (
    <Card>
      <CardContent
        className={
          User.isPro(user) ? "bg-primary-default" : "bg-primary-lighter"
        }
      >
        <UserInfos
          user={user}
          variant="search"
          href={router.generate("app_account_profile_show", params)}
        />
      </CardContent>
    </Card>
  )
}
